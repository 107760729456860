import { BaseApiService } from '@/shared/services/base-api.service';
import type { IAuthApiService } from '../auth-api.service';
import type { AuthResponse } from '@/models/auth/AuthResponse';
import type { LoginCredentials } from '@/models/auth/LoginCredentials';
import axios from 'axios';
import type { User } from '@/models/auth/User';

export class AuthApiService extends BaseApiService implements IAuthApiService {
    async login(model: LoginCredentials): Promise<User> {
        const url = `${this.BASE_URL}/auth/sign_in`;
        const response = await axios.post<AuthResponse>(url, model);

        if (response.status !== 200) throw new Error('Erro during login');

        return response.data.data;
    }

    async verify(): Promise<User> {
        const url = `${this.BASE_URL}/auth/validate_token`;
        const response = await axios.get<AuthResponse>(url);

        if (response.status !== 200) throw new Error('Token invalid');

        return response.data.data;
    }
}
