import type { AuthModel } from '@/models/auth/LoginResult';
import axios from 'axios';
import type { AxiosResponse, InternalAxiosRequestConfig } from 'axios';

axios.interceptors.request.use((config: InternalAxiosRequestConfig) => {
    config.headers = config.headers ?? {};
    config.headers.Authorization = import.meta.env.VITE_API_AUTHORIZATION;

    const currentUser = localStorage.getItem('currentUser');

    if (!currentUser) return config;

    const memberToken = JSON.parse(currentUser) as AuthModel;

    if (memberToken) {
        config.headers['access-token'] = memberToken.accessToken;
        config.headers['client'] = memberToken.client;
        config.headers['uid'] = memberToken.uid;
    }

    return config;
});

axios.interceptors.response.use((response: AxiosResponse) => {
    const token = response.headers['access-token'];
    const client = response.headers['client'];
    const uid = response.headers['uid'];
    const expiry = response.headers['expiry'];

    if (token && client && uid) {
        const auth: AuthModel = {
            accessToken: token,
            client: client,
            uid: uid,
            expiry: expiry
        };
        localStorage.setItem('currentUser', JSON.stringify(auth));
    }

    return response;
});
