import type { RouteRecordRaw } from 'vue-router';

const loginRoutes: Array<RouteRecordRaw> = [
    {
        path: '/login',
        name: 'Login',
        component: () => import('../views/LoginView.vue'),
        meta: {
            anonymousOnly: true
        }
    },
    {
        path: '/register',
        name: 'Register',
        component: () => import('../views/CreateAccountView.vue'),
        meta: {
            anonymousOnly: true
        }
    },
    {
        path: '/forgot-password',
        name: 'ForgotPassword',
        component: () => import('../views/ForgotPasswordView.vue'),
        meta: {
            anonymousOnly: true
        }
    },
    {
        path: '/reset-password',
        name: 'ResetPassword',
        component: () => import('../views/ResetPasswordView.vue'),
        meta: {
            anonymousOnly: true
        }
    }
];

export default loginRoutes;
