// import './assets/main.css';
import './assets/global.css';

import { createApp } from 'vue';
import App from './App.vue';
import router from './router';

import i18n from './i18n';

import { createPinia } from 'pinia';

import { useAuthStore } from './store/login.store';

import { applyRouterGuard } from './shared/guards/auth.guard';

import './shared/interceptors/auth.interceptor';

function createVueApp() {
    const app = createApp(App);

    const pinia = createPinia();

    app.use(i18n);
    app.use(pinia);

    const authStore = useAuthStore();

    authStore
        .verifyToken()
        .then(async () => mountApp())
        .catch(async () => {
            await authStore.logout(false);
            mountApp();
        });

    function mountApp() {
        app.use(router);
        applyRouterGuard(router);

        app.mount('#app');
    }
}

createVueApp();
