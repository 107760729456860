import { ref, computed } from 'vue';
import { defineStore } from 'pinia';
import type { User } from '@/models/auth/User';
import { AuthApiService } from '@/services/impl/auth-api-service';
import type { LoginCredentials } from '@/models/auth/LoginCredentials';

export const useAuthStore = defineStore('authStore', () => {
    const currentUserKey = 'currentUser';

    const api = new AuthApiService();

    const loggedUser = ref<User | null>(null);
    const isLoggedIn = computed(() => loggedUser.value !== null);

    const login = async (model: LoginCredentials): Promise<void> => {
        const result = await api.login(model);

        if (!result) return Promise.reject();

        loggedUser.value = result;
    };

    const logout = async (withPost: boolean): Promise<void> => {
        localStorage.removeItem(currentUserKey);
        loggedUser.value = null;
    };

    const verifyToken = async (): Promise<void> => {
        const result = await api.verify();

        if (!result) return Promise.reject();
        loggedUser.value = result;
    };

    return {
        isLoggedIn,

        login,
        logout,
        verifyToken
    };
});
