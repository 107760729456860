import loginRoutes from './router/login.routes';
import homeRoutes from './router/home.routes';
import { createRouter, createWebHistory } from 'vue-router';

const routes = [...loginRoutes, ...homeRoutes];

const router = createRouter({
    history: createWebHistory(),
    routes
});

export default router;
