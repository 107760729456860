import type { Router } from 'vue-router';
import { nextTick } from 'vue';
import { useAuthStore } from '@/store/login.store';

export function applyRouterGuard(router: Router): void {
    const DEFAULT_TAB_TITLE = 'Trainingsmanager';

    const authStore = useAuthStore();

    router.beforeEach((to, from, next) => {
        const isLoggedIn = !!authStore.isLoggedIn;
        const anonymousOnlyRoute = to.meta?.anonymousOnly;
        const allowAnonymous = to.meta?.allowAnonymous;

        if (allowAnonymous) {
            next();
        } else if (anonymousOnlyRoute && isLoggedIn) {
            // anonymous route like login - redirect to home page
            next({ name: 'Home' });
        } else if (!anonymousOnlyRoute && !isLoggedIn) {
            // logged in route and not logged in - redirect to login
            next({ name: 'Login', query: { redirect: to.path } });
        } else {
            // else pass
            next();
        }
    });

    router.afterEach((to: any, from: any) => {
        nextTick(() => {
            document.title = to.meta.title || DEFAULT_TAB_TITLE;
        });
    });
}
