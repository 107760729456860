import type { RouteRecordRaw } from 'vue-router';

const homeRoutes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'Home',
        component: () => import('../views/HomeView.vue')
    }
];

export default homeRoutes;
